<template>
  <modal
    name="offer-modal"
    :resizable="true"
    :height="'auto'"
    :width="'80%'"
    :scrollable="true"
  >
    <div class="row bg-secondary">
      <!-- <h1> Installation modal </h1> -->
      <div class="col-6">
        <p v-html="header" class="float-left align-middle text-white mt-2">
          {{ header }}
        </p>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="float-right">
          <!-- <button
            id="editinstallationButton"
            class="btn btn-sm btn-pill btn-info my-1 mx-2"
            @click="addOffer(offer)"
          >
            Zapisz oferte
          </button> -->
          <button
            id="editinstallationButton"
            class="btn btn-sm btn-pill btn-info my-1 mx-2"
            @click="generateOffer(offer)"
          >
            Generuj PDF
          </button>
          <button
            class="btn btn-sm btn-pill btn-danger my-1 mx-2"
            @click="$emit('closeModal')"
          >
            Zamknij
          </button>
        </div>
      </div>
    </div>
    <div id="toPrint" class="mx-3">
      <div class="row">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="row text-right">
        <div class="col-12">
          <p>
            ul. Wąska 4 Zaborze, 43-520 Chybie <br />
            biuro@klim-bud.pl <br />
            tel: 574 559 890
            <br />
            NIP:548-235-02-89<br />
            REGON: 241002771 <br />
            www.klim-bud.pl
          </p>
        </div>
      </div>
      <div class="row text-right">
        <div class="col-12 my-4 mb-3">Chybie dn. {{ date.toLocaleDateString("pl") }}</div>
      </div>
      <div class="row my-4 text-center">
        <div class="col-12">
          <h3 class="my-4">OFERTA CENOWA</h3>
          <h5>{{ offerNumber }}</h5>
          <br /><br />
          <h5 class="my-4" v-html="header2">
            {{ header2 }}
          </h5>
          <br />
          <p class="my-4" v-html="subheader">
            {{ subheader }}
          </p>
        </div>
      </div>

      <div class="html2pdf__page-break"></div>

      <div class="row">
        <img src="../assets/logo.png" alt="" />
      </div>

      <!-- <div class="row text-right">
        <div class="col-12">
          <p>
            ul. Wąska 4 Zaborze, 43-520 Chybie <br />
            biuro@klim-bud.pl <br />
            tel: 504 041 946
            <br />
            NIP:548-235-02-89<br />
            REGON: 241002771 <br />
            www.klim-bud.pl
          </p>
        </div>
      </div> -->

      <div class="row text-center">
        <div class="col-12 my-5">
          <h5 v-html="header">{{ header }}</h5>
        </div>

        <!-- <div class="row">
          <div class="col-12 text-center">
            <img style="max-height: 500px" :src="`${imageUrl}`" alt="" />
          </div>

          <div class="col-3 ml-5 text-center border">
            <small>Uwagi do oferty:</small> <br />
            {{ notice }}
          </div>
        </div> -->

        <table style="font-size: 14px" class="table mt-2 table-sm table-striped">
          <thead>
            <th>Przedmiot</th>
            <th>j.m.</th>
            <th>ilość</th>
            <th>cena netto</th>
            <th>wartość netto</th>
            <th>wartość brutto</th>
          </thead>
          <tbody>
            <tr v-for="item in offer" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.measure }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.price }}</td>
              <td>{{ (item.amount * item.price).toFixed(2) }}</td>
              <td>{{ (item.amount * item.price * vat).toFixed(2) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><b>Suma PLN:</b></td>
              <td></td>
              <td></td>
              <td v-if="vat == '1.08'"><b>VAT: 8%</b></td>
              <td v-else-if="vat == '1.23'"><b>VAT: 23%</b></td>
              <td>
                <b>{{ netto.toFixed(2) }}</b>
              </td>
              <td>
                <b>{{ brutto.toFixed(2) }}</b>
              </td>
            </tr>
          </tfoot>
        </table>

        <div class="col-12 my-3 text-center">
          <img class="img-fluid" style="max-height: 350px" :src="`${imageUrl}`" alt="" />
        </div>

        <div class="col-6 my-5 text-center">
          <small>Uwagi do oferty:</small> <br />
          {{ notice }}
        </div>
        <div class="col-6 my-5 text-center">
          <small>Wykonawca F.H.U KLIM-BUD:</small> <br />
          {{ user.name }} <br />
          {{ user.phoneNumber }}
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import html2pdf from "html2pdf.js";
export default {
  props: [
    "offer",
    "vat",
    "netto",
    "brutto",
    "imageUrl",
    "header",
    "notice",
    "user",
    "header2",
    "subheader",
    "offerNumber",
    "number",
  ],
  data() {
    return {
      date: new Date(),
    };
  },
  methods: {
    generateOffer() {
      console.log(this.imageUrl);
      console.log(this.offersElements);
      var element = document.getElementById("toPrint");
      html2pdf(element, {
        margin: 0.1,
        filename: prompt("Podaj nazwę pliku"),
        image: { type: "jpeg", quality: 1 },
        html2canvas: { dpi: 192 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });

      this.addOffer();
    },
    addOffer() {
      console.log("offer");
      let name = prompt("Nazwa klienta/Adres Email");
      if (!name) {
        name = "brak informacji";
      }
      // if (this.contractor) prompt("Nazwa klienta/Adres Email"){
      this.$store.dispatch("createOffer", {
        contractor: name,
        date: this.date,
        offer: this.offer,
        vat: this.vat,
        netto: this.netto,
        brutto: this.brutto,
        imageUrl: this.imageUrl,
        header: this.header,
        notice: this.notice,
        user: this.user,
        header2: this.header2,
        subheader: this.subheader,
        offerNumber: this.offerNumber,
      });
      this.$store.dispatch("updateCounter", this.number).then(() => {
        this.$emit("closeModal");
      });
      // } else {
      //   alert("brak kontrahenta");
      // }
    },
  },
};
</script>
